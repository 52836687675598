.panel-layout {
    margin-top: 30px;
}
.passport-authorize .scopes {
    margin-top: 20px;
}
.passport-authorize .buttons {
    margin-top: 25px;
    text-align: center;
}
.passport-authorize .btn {
    width: 125px;
}
.passport-authorize .btn-approve {
    margin-right: 15px;
}
.passport-authorize form {
    display: inline;
}

